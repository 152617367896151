import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import SideNav from "../Components/SideNav";

// *** administrador *** //
import Login from "../Views/Index";

// users
import Usuarios from "../Views/Usuarios/Usuario";
import NuevoUsuario from "../Views/Usuarios/NuevoUsuario";
import EditarUsuario from "../Views/Usuarios/EditarUsuario";

// vendedores
import NuevoVendedor from "../Views/Vendedores/NuevoVendedor";
import EditarVendedor from "../Views/Vendedores/EditarVendedor";

// Productos
import Productos from "../Views/Productos/Productos";
import NuevoProducto from "../Views/Productos/NuevoProducto";
import EditarProducto from "../Views/Productos/EditarProducto";

// ventas
import NuevaVenta from "../Views/Ventas/NuevaVenta";
import Ventas from "../Views/Ventas/Ventas";
import Venta from "../Views/Ventas/Venta";

// clientes
import Clientes from "../Views/Clientes/Clientes";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Login />} />
    </Routes>
    <SideNav>
      <Routes>
        {/* usuarios */}
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/nuevo-usuario" element={<NuevoUsuario />} />
        <Route path="/editar-usuario/:id" element={<EditarUsuario />} />

        {/* vendedores */}
        <Route path="/nuevo-vendedor" element={<NuevoVendedor />} />
        <Route path="/editar-vendedor/:id" element={<EditarVendedor />} />

        {/* porductos */}
        <Route path="/productos" element={<Productos />} />
        <Route path="/nuevo-producto" element={<NuevoProducto />} />
        <Route path="/editar-producto/:id" element={<EditarProducto />} />

        {/* ventas */}
        <Route path="/nueva-venta" element={<NuevaVenta />} />
        <Route path="/ventas" element={<Ventas />} />
        <Route path="/venta/:id" element={<Venta />} />

        {/* clientes */}
        <Route path="/datos-clientes" element={<Clientes />} />
      </Routes>
    </SideNav>
  </BrowserRouter>
);

export default Router;
