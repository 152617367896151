import React, { useContext, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import M from "materialize-css";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

import logo from "../../Images/logo.png";
import insta from "../../Images/instagram.jpeg";
import face from "../../Images/facebook.jpeg";
import tiktok from "../../Images/tiktok.jpeg";

const NuevaVenta = (props) => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Nueva Venta");

  const ticketRef = useRef(null);

  //   para realizar las consultas
  const [consulta, setConsulta] = useState({ consulta: "" });
  const [result] = useDatabase(consulta);

  const [productos, setProductos] = useState([]);
  const [venta, setVenta] = useState({
    fecha: new Date(),
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    estado: "",
    ciudad: "",
    vendedor: "",
    pago: "",
  });
  const [efectivo, setEfectivo] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalIVA, setTotalIVA] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [productosVenta, setProductosVentas] = useState([]);

  useEffect(() => {
    if (props.productos && props.vendedores) {
      setProductos([...props.productos]);
      var elems = document.querySelectorAll("select");
      M.FormSelect.init(elems);
    }
    if (result) {
      resetVista();
    }
  }, [props, result]);

  const buscarNombre = (e) => {
    let copiaProductosProps = [...props.productos];
    let inputModelo = document.getElementById("modelo");

    // quitamos los productos seleccionados de los props
    productosVenta.map((producto) => {
      let indexProductoSeleccionado = copiaProductosProps.indexOf(producto);
      if (indexProductoSeleccionado >= 0) {
        copiaProductosProps.splice(indexProductoSeleccionado, 1);
      }
    });

    if (e.target.value !== "") {
      inputModelo.disabled = true;

      let filtro = copiaProductosProps.filter((producto) => {
        return producto.nombre
          .toUpperCase()
          .includes(e.target.value.toUpperCase());
      });

      setProductos(filtro);
    } else {
      setProductos([...copiaProductosProps]);

      inputModelo.disabled = false;
    }
  };

  const buscarModelo = (e) => {
    let copiaProductosProps = [...props.productos];
    let inputNombre = document.getElementById("nombre_producto");

    // quitamos los productos seleccionados de los props
    productosVenta.map((producto) => {
      let indexProductoSeleccionado = copiaProductosProps.indexOf(producto);
      if (indexProductoSeleccionado >= 0) {
        copiaProductosProps.splice(indexProductoSeleccionado, 1);
      }
    });

    if (e.target.value !== "") {
      inputNombre.disabled = true;

      let filtro = copiaProductosProps.filter((producto) => {
        return producto.modelo
          .toUpperCase()
          .includes(e.target.value.toUpperCase());
      });

      setProductos(filtro);
    } else {
      setProductos([...copiaProductosProps]);

      inputNombre.disabled = false;
    }
  };

  const leerDato = (e) => {
    setVenta({
      ...venta,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "pago") {
      setEfectivo(0);
    }
  };

  const leerCantidad = (e, producto) => {
    let copiaProductosVenta = [...productosVenta];
    let cantidad = e.target.value === "" ? 0 : e.target.value;

    // buscamos el producto que se cambio
    let indexProducto = copiaProductosVenta.indexOf(producto);

    calculaTotal(
      copiaProductosVenta,
      cantidad,
      copiaProductosVenta[indexProducto].precio,
      copiaProductosVenta[indexProducto].precio_iva,
      indexProducto
    );
  };

  const leerPrecio = (e, producto) => {
    let precio = e.target.value === "" ? 0 : e.target.value;
    let copiaProductosVenta = [...productosVenta];

    // buscamos el producto que se cambio
    let indexProducto = copiaProductosVenta.indexOf(producto);

    calculaTotal(
      copiaProductosVenta,
      copiaProductosVenta[indexProducto].cantidad,
      parseFloat(precio),
      copiaProductosVenta[indexProducto].precio_iva,
      indexProducto
    );
  };

  const leerPrecioIVA = (e, producto) => {
    let precioIVA = e.target.value === "" ? 0 : e.target.value;
    let copiaProductosVenta = [...productosVenta];

    // buscamos el producto que se cambio
    let indexProducto = copiaProductosVenta.indexOf(producto);

    calculaTotal(
      copiaProductosVenta,
      copiaProductosVenta[indexProducto].cantidad,
      copiaProductosVenta[indexProducto].precio,
      parseFloat(precioIVA),
      indexProducto
    );
  };

  const calculaTotal = (
    productos,
    cantidad,
    precio,
    precioIva,
    indexProducto
  ) => {
    let copiaTotal = total;
    let copiaTotalIVA = totalIVA;

    // cambiamos el total y el total + IVA
    // restamos la cantidad que anterior mente tenia
    copiaTotal =
      copiaTotal -
      productos[indexProducto].cantidad * productos[indexProducto].precio;
    copiaTotalIVA =
      copiaTotalIVA -
      productos[indexProducto].cantidad * productos[indexProducto].precio_iva;

    // egregamos la nueva cantidad
    copiaTotal = copiaTotal + parseInt(cantidad) * precio;
    copiaTotalIVA = copiaTotalIVA + parseInt(cantidad) * precioIva;

    // cambiamos la cantidad
    productos[indexProducto].cantidad = parseInt(cantidad);
    productos[indexProducto].precio = parseInt(precio);
    productos[indexProducto].precio_iva = parseInt(precioIva);
    setProductosVentas(productos);
    setTotal(copiaTotal);
    setTotalIVA(copiaTotalIVA);
  };

  const leerDescuento = (e) => {
    if (e.target.value === "") {
      setDescuento(0);
    } else {
      setDescuento(parseFloat(e.target.value).toFixed(2));
    }
  };

  const leerEfectivo = (e) => {
    if (e.target.value === "") {
      setEfectivo(0);
    } else {
      setEfectivo(parseFloat(e.target.value).toFixed(2));
    }
  };

  const seleccionarProducto = (producto) => {
    // lo quitamos de productos
    let copiaProductos = [...productos];
    let index = productos.indexOf(producto);
    copiaProductos.splice(index, 1);
    setProductos(copiaProductos);

    // agregamos a productso ventas
    let copiaProductosVenta = [...productosVenta];
    copiaProductosVenta.push({ ...producto, cantidad: 1 });
    setProductosVentas(copiaProductosVenta);

    // obtenemos el total
    let copiaTotal = total;
    let copiaTotalIVA = totalIVA;
    copiaTotal = copiaTotal + parseFloat(producto.precio);
    copiaTotalIVA = copiaTotalIVA + parseFloat(producto.precio_iva);
    setTotal(copiaTotal);
    setTotalIVA(copiaTotalIVA);
    // setTotal(parseFloat(copiaTotal).toFixed(2));
    // setTotalIVA(parseFloat(copiaTotalIVA).toFixed(2));
  };

  const quitarProducto = (producto) => {
    let copiaProductosVenta = [...productosVenta];
    let index = copiaProductosVenta.indexOf(producto);
    let copiaTotal = total;
    let copiaTotalIVA = totalIVA;

    // restamos la cantidad que anterior mente tenia
    copiaTotal =
      copiaTotal -
      copiaProductosVenta[index].cantidad * copiaProductosVenta[index].precio;
    copiaTotalIVA =
      copiaTotalIVA -
      copiaProductosVenta[index].cantidad *
        copiaProductosVenta[index].precio_iva;

    // lo quitamos de productos ventas
    copiaProductosVenta.splice(index, 1);
    setProductosVentas(copiaProductosVenta);

    // agregamos a productso
    let copiaProductos = [...productos];
    copiaProductos.push(producto);
    setProductos(copiaProductos);
    setTotal(copiaTotal);
    setTotalIVA(copiaTotalIVA);
  };

  const pregunta = (e) => {
    e.preventDefault();
    if (
      productosVenta.length === 0 ||
      venta.vendedor === "" ||
      venta.pago === ""
    ) {
      return Swal.fire({
        title: "Faltan datos",
        text: "Para realizar una venta debes seleccionar productos",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }

    Swal.fire({
      title: "¿Estas seguro que quieres concluir con la venta?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#2DCD22",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        nuevaVenta();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire({
          title: "Puedes continuar modificando la venta",
          text: "Cancelado",
          confirmButtonColor: "#2DCD22",
          confirmButtonText: "Continuar",
          icon: "error",
        });
      }
    });
  };

  const nuevaVenta = () => {
    let data = {
      ...venta,
      productos: productosVenta,
      total: parseFloat(total).toFixed(2),
      totalIVA: parseFloat(totalIVA).toFixed(2),
      descuento,
    };

    setConsulta({
      consulta: "agregar",
      collection: "ventas",
      state: data,
    });
  };

  const imrpimir = async () => {
    if (ticketRef.current) {
      const content = ticketRef.current.innerHTML;
      const printWindow = window.open("", "", "height=1000,width=1900");
      printWindow.document.write("<html><head><title>Ticket de compra</title>");
      printWindow.document.write("</head><body>");
      printWindow.document.write(content);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      setTimeout(() => {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }, 100);
    }
  };

  const resetVista = (e) => {
    if (e) e.preventDefault();
    // document.getElementById("formulario_producto").reset();
    document.getElementById("formulario_cliente").reset();
    setProductos(props.productos);
    setProductosVentas([]);
    setTotal(0);
    setTotalIVA(0);
    setDescuento(0);
    setConsulta({ consulta: "" });
    if (!e) imrpimir();
  };

  return (
    <div name="Usuarios" className="contenedor-1 row">
      <div className="col s4">
        <p className="titulo-1" style={{ marginBottom: "0" }}>
          Buscador de productos
        </p>
        <form id="formulario_cliente" onSubmit={pregunta}>
          <div>
            <div className="input-field">
              <input
                onChange={buscarNombre}
                id="nombre_producto"
                name="nombre_producto"
                type="text"
                className="validate"
              />
              <label for="nombre_producto">Nombre del producto:</label>
            </div>
            <div className="input-field">
              <input
                onChange={buscarModelo}
                id="modelo"
                name="modelo"
                type="text"
                className="validate"
              />
              <label for="modelo">Modelo del producto:</label>
            </div>
            {props.vendedores ? (
              <div class="input-field col s12">
                <select name="vendedor" onChange={leerDato} required>
                  <option value="" selected>
                    Selecciona un vendedro
                  </option>
                  {props.vendedores.map((vendedor) => (
                    <option value={vendedor.nombre}>{vendedor.nombre}</option>
                  ))}
                </select>
                <label>Vendedores</label>
              </div>
            ) : (
              <h1>cargando</h1>
            )}
          </div>

          <p className="titulo-1" style={{ marginBottom: "0" }}>
            Datos de la venta
          </p>
          <div style={{ width: "100%", maxHeight: "55vh", overflow: "auto" }}>
            {/* formulario venta */}
            <div>
              <div className="input-field">
                <input
                  onChange={leerDato}
                  id="nombre"
                  name="nombre"
                  type="text"
                  className="validate"
                  required
                />
                <label for="nombre">Nombre del cliente:</label>
              </div>
              <div className="input-field">
                <input
                  onChange={leerDato}
                  id="apellido"
                  name="apellido"
                  type="text"
                  className="validate"
                  required
                />
                <label for="apellido">Apellido del cliente:</label>
              </div>
              <div className="input-field">
                <input
                  onChange={leerDato}
                  id="correo"
                  name="correo"
                  type="text"
                  className="validate"
                  required
                />
                <label for="correo">Correo del cliente:</label>
              </div>
              <div className="input-field">
                <input
                  onChange={leerDato}
                  id="telefono"
                  name="telefono"
                  type="text"
                  className="validate"
                  required
                />
                <label for="telefono">Telefono del cliente:</label>
              </div>
              <div className="input-field">
                <input
                  onChange={leerDato}
                  id="estado"
                  name="estado"
                  type="text"
                  className="validate"
                  required
                />
                <label for="estado">Estado del cliente:</label>
              </div>
              <div className="input-field">
                <input
                  onChange={leerDato}
                  id="ciudad"
                  name="ciudad"
                  type="text"
                  className="validate"
                  required
                />
                <label for="ciudad">Ciudad del cliente:</label>
              </div>
              <div className="input-field">
                <input
                  onChange={leerDescuento}
                  id="descuento"
                  name="descuento"
                  type="number"
                  className="validate"
                  step="any"
                  min={0}
                />
                <label for="descuento">Descuento:</label>
              </div>
              <div class="input-field col s12">
                <select name="pago" onChange={leerDato} required>
                  <option value="" selected>
                    Selecciona el tipo de pago
                  </option>
                  <option value="Tarjeta de Credito">Tarjeta de Credito</option>
                  <option value="Tarjeta de Debito">Tarjeta de Debito</option>
                  <option value="Efectivo">Efectivo</option>
                  <option value="Transferencia">Transferencia</option>
                </select>
                <label>Vendedores</label>
              </div>
              {venta.pago === "Efectivo" && (
                <div className="input-field  col s12">
                  <input
                    onChange={leerEfectivo}
                    id="efectivo"
                    name="efectivo"
                    type="number"
                    className="validate"
                    step="any"
                    min={0}
                  />
                  <label for="efectivo">Efectivo:</label>
                </div>
              )}
            </div>

            {/* informacion de cobros y cmabios */}
            <div>
              <div>
                <p style={{ marginBottom: "0" }}>
                  Total: <b>${parseFloat(total).toFixed(2)}</b>
                </p>
                <p style={{ marginTop: "0" }}>
                  Total + IVA: <b>${parseFloat(totalIVA).toFixed(2)}</b>
                </p>
              </div>
              {descuento !== 0 && (
                <div>
                  <p style={{ marginBottom: "0" }}>
                    Total con descuento:{" "}
                    <b>
                      $
                      {parseFloat(total * ((100 - descuento) / 100)).toFixed(2)}
                    </b>
                  </p>
                  <p style={{ marginTop: "0" }}>
                    Total + IVA con descuento:{" "}
                    <b>
                      $
                      {parseFloat(totalIVA * ((100 - descuento) / 100)).toFixed(
                        2
                      )}
                    </b>
                  </p>
                </div>
              )}
              {venta.pago !== "" &&
                (venta.pago === "Efectivo" ? (
                  <p style={{ margin: "0" }}>
                    {venta.pago}: <b>${parseFloat(efectivo).toFixed(2)}</b>
                  </p>
                ) : descuento !== 0 ? (
                  <p style={{ margin: "0" }}>
                    {venta.pago}:{" "}
                    <b>
                      $
                      {parseFloat(totalIVA * ((100 - descuento) / 100)).toFixed(
                        2
                      )}
                    </b>
                  </p>
                ) : (
                  <p style={{ margin: "0" }}>
                    {venta.pago}: <b>${parseFloat(totalIVA).toFixed(2)}</b>
                  </p>
                ))}
              {venta.pago === "Efectivo" && (
                <div>
                  {descuento !== 0 ? (
                    <p style={{ margin: "0" }}>
                      Cambio:{" "}
                      <b>
                        $
                        {parseFloat(
                          efectivo - totalIVA * ((100 - descuento) / 100)
                        ).toFixed(2)}
                      </b>
                    </p>
                  ) : (
                    <p style={{ margin: "0" }}>
                      Cambio:{" "}
                      <b>${parseFloat(efectivo - totalIVA).toFixed(2)}</b>
                    </p>
                  )}
                </div>
              )}
            </div>

            <div style={{ display: "flex", gap: "15px" }}>
              <button className="boton-verde" type="submit">
                Realizar Venta
              </button>
              <button onClick={resetVista} className="boton-morado">
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* tablas de productos */}
      <div className="col s8" style={{ paddingLeft: "50px" }}>
        <p className="titulo-1">Lista de todos los productos</p>
        <div style={{ maxHeight: "33vh", overflow: "auto" }}>
          <table>
            <thead>
              <tr className="tabla-header">
                <td>Nombre</td>
                <td>Modelo</td>
                <td>Precio</td>
                <td>Precio IVA</td>
              </tr>
            </thead>
            <tbody>
              {props.productos ? (
                productos.map((producto) => (
                  <tr
                    id={producto.id}
                    onClick={() => {
                      seleccionarProducto(producto);
                    }}
                    className="tabla-body tabla-venta"
                  >
                    <td>{producto.nombre}</td>
                    <td>{producto.modelo}</td>
                    <td>${parseFloat(producto.precio).toFixed(2)}</td>
                    <td>${parseFloat(producto.precio_iva).toFixed(2)}</td>
                  </tr>
                ))
              ) : (
                <h1>cargando..</h1>
              )}
            </tbody>
          </table>
        </div>

        <p className="titulo-1">Productos seleccionados</p>
        <div style={{ maxHeight: "33vh", overflow: "auto" }}>
          <table>
            <tr className="tabla-header">
              <td>Nombre</td>
              <td>Modelo</td>
              <td>Precio</td>
              <td>Precio IVA</td>
              <td>Cantidad</td>
            </tr>
            {productosVenta.map((producto) => (
              <tr className="tabla-body">
                <td
                  className="tabla-venta"
                  onClick={() => {
                    quitarProducto(producto);
                  }}
                >
                  {producto.nombre}
                </td>
                <td
                  className="tabla-venta"
                  onClick={() => {
                    quitarProducto(producto);
                  }}
                >
                  {producto.modelo}
                </td>
                <td>
                  <input
                    style={{ width: "40px" }}
                    onChange={(e) => {
                      leerPrecio(e, producto);
                    }}
                    defaultValue={parseFloat(producto.precio).toFixed(2)}
                    min={1}
                    id="precio"
                    name="precio"
                    type="number"
                    className="validate"
                  />
                </td>
                <td>
                  <input
                    style={{ width: "40px" }}
                    onChange={(e) => {
                      leerPrecioIVA(e, producto);
                    }}
                    defaultValue={parseFloat(producto.precio_iva).toFixed(2)}
                    min={1}
                    id="precio"
                    name="precio"
                    type="number"
                    className="validate"
                  />
                </td>
                <td>
                  <input
                    style={{ width: "40px" }}
                    onChange={(e) => {
                      leerCantidad(e, producto);
                    }}
                    defaultValue={producto.cantidad}
                    min={1}
                    id="cantidad"
                    name="cantidad"
                    type="number"
                    className="validate"
                  />
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      {/* tabla de imprecion de datos en ticket */}
      <div
        style={{
          display: "none",
        }}
        ref={ticketRef}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginLeft: "-4px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "-10px",
            }}
          >
            <img
              src={logo}
              alt="SNS COSMTECIOS NATURALES SA DE CV"
              style={{ width: "200px" }}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "12px" }}>
              Prolongación Pino Suarez, 1874 - A <br />
              Colonia El Vigia, Zapopan <br />
              Jalisco, C.P. 45150 <br />
              Tel: 33 3364 9610
            </p>
            <p style={{ marginBottom: "0", lineHeight: "11px" }}>
              * * * * * * * * * * * * * * * * * * *
            </p>
            <p style={{ fontSize: "14px", margin: "0" }}>
              <b>Ticket de Compra</b>
            </p>
            <p style={{ marginTop: "0" }}>
              * * * * * * * * * * * * * * * * * * *
            </p>
          </div>
          <table style={{ width: "100%", marginLeft: "10px" }}>
            <tr className="tabla-header">
              <td style={{ fontSize: "12px" }}>Nombre</td>
              <td style={{ fontSize: "12px" }}>Cantidad</td>
              <td style={{ fontSize: "12px" }}>Precio</td>
              <td style={{ fontSize: "12px" }}>Precio IVA</td>
            </tr>
            {productosVenta.map((producto) => (
              <tr
                onClick={() => {
                  quitarProducto(producto);
                }}
                className="tabla-body tabla-venta"
              >
                <td style={{ fontSize: "12px", width: "90px" }}>
                  {producto.nombre}
                </td>
                <td style={{ fontSize: "12px", textAlign: "center" }}>
                  {producto.cantidad}
                </td>
                <td style={{ fontSize: "12px" }}>${producto.precio}</td>
                <td style={{ fontSize: "12px" }}>${producto.precio_iva}</td>
              </tr>
            ))}
          </table>
          {/* datos de la venta */}
          {/* <div style={{ marginLeft: "10px" }}>
            <p style={{ margin: "0", fontSize: "12px" }}>
              {venta.nombre} {venta.apellido}
            </p>
            <p style={{ margin: "0", fontSize: "12px" }}>{venta.correo}</p>
            <p style={{ margin: "0", fontSize: "12px" }}>{venta.telefono}</p>
            <p style={{ margin: "0", fontSize: "12px" }}>
              {venta.ciudad}, {venta.estado}
            </p>
          </div> */}
          <div style={{ marginLeft: "10px" }}>
            <div style={{ textAlign: "center" }}>
              <p style={{ margin: "0" }}>
                * * * * * * * * * * * * * * * * * * *
              </p>
            </div>
            <p style={{ margin: "0", fontSize: "12px" }}>
              <b>Nombre del vendedor: {venta.vendedor}</b>
            </p>
            <p style={{ margin: "0", fontSize: "13px" }}>
              Total: <b>${parseFloat(total).toFixed(2)}</b>
            </p>
            <p style={{ margin: "0", fontSize: "13px" }}>
              Total + IVA: <b>${parseFloat(totalIVA).toFixed(2)}</b>
            </p>
            {descuento !== 0 && (
              <div>
                <p style={{ margin: "0", fontSize: "13px" }}>
                  Total con descuento (%{descuento}):{" "}
                  <b>
                    ${parseFloat(total * ((100 - descuento) / 100)).toFixed(2)}
                  </b>
                </p>
                <p style={{ margin: "0", fontSize: "13px" }}>
                  Total + IVA con descuento (%{descuento}):{" "}
                  <b>
                    $
                    {parseFloat(totalIVA * ((100 - descuento) / 100)).toFixed(
                      2
                    )}
                  </b>
                </p>
              </div>
            )}
            {venta.pago === "Efectivo" ? (
              <p style={{ margin: "0", fontSize: "13px" }}>
                {venta.pago}: <b>${parseFloat(efectivo).toFixed(2)}</b>
              </p>
            ) : descuento !== 0 ? (
              <p style={{ margin: "0", fontSize: "13px" }}>
                {venta.pago}:{" "}
                <b>
                  ${parseFloat(totalIVA * ((100 - descuento) / 100)).toFixed(2)}
                </b>
              </p>
            ) : (
              <p style={{ margin: "0", fontSize: "13px" }}>
                {venta.pago}: <b>${parseFloat(totalIVA).toFixed(2)}</b>
              </p>
            )}
            {venta.pago === "Efectivo" && (
              <div>
                {descuento !== 0 ? (
                  <p style={{ margin: "0", fontSize: "13px" }}>
                    Cambio:{" "}
                    <b>
                      $
                      {parseFloat(
                        efectivo - totalIVA * ((100 - descuento) / 100)
                      ).toFixed(2)}
                    </b>
                  </p>
                ) : (
                  <p style={{ margin: "0", fontSize: "13px" }}>
                    Cambio: <b>${parseFloat(efectivo - totalIVA).toFixed(2)}</b>
                  </p>
                )}
              </div>
            )}
            <div style={{ textAlign: "center", paddingBottom: "50px" }}>
              <p style={{ margin: "0" }}>
                * * * * * * * * * * * * * * * * * * *
              </p>
              <p style={{ fontSize: "16px", margin: "0" }}>
                ¡GRACIAS POR TU COMPRA! <br />
                SIGUENOS EN <br />
                NUESTRAS REDES
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img style={{ width: "70px" }} src={face} alt="" />
                  <p
                    style={{
                      margin: "0",
                      fontSize: "11px",
                      marginTop: "-12px",
                    }}
                  >
                    Facebook
                  </p>
                </div>
                <div>
                  <img style={{ width: "70px" }} src={insta} alt="" />
                  <p
                    style={{
                      margin: "0",
                      fontSize: "11px",
                      marginTop: "-12px",
                    }}
                  >
                    Instagram
                  </p>
                </div>
                <div>
                  <img style={{ width: "70px" }} src={tiktok} alt="" />
                  <p
                    style={{
                      margin: "0",
                      fontSize: "11px",
                      marginTop: "-12px",
                    }}
                  >
                    TikTok
                  </p>
                </div>
              </div>
              <p style={{ color: "#ffffff", marginTop: "10px" }}>.</p>
            </div>
          </div>
        </div>
      </div>
      {/* <button onClick={imrpimir} className="boton-morado">
        imprimir
      </button> */}
    </div>
  );
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "productos",
      orderBy: "nombre",
    },
    {
      collection: "vendedores",
      orderBy: "nombre",
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    productos: ordered.productos,
    vendedores: ordered.vendedores,
  }))
)(NuevaVenta);
