import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";
import { useEffect } from "react";

const Usuarios = (props) => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Usuarios y Vendedores");

  const [consulta, setConsulta] = useState({ consulta: "" });
  useDatabase(consulta);

  const eliminar = (id) => {
    setConsulta({ consulta: "eliminar", collection: "usuarios", id });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 500);
  };

  const eliminarVendedor = (id) => {
    setConsulta({ consulta: "eliminar", collection: "vendedores", id });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 500);
  };

  return (
    <div name="Usuarios" className="contenedor-1">
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p className="titulo-1">Todos los Usuarios</p>
          <Link className="boton-morado" to="/nuevo-usuario">
            Nuevo Usuario
          </Link>
        </div>

        <div>
          <table>
            <tr className="tabla-header">
              <td>Nombre</td>
              <td>Correo</td>
              <td>Accion</td>
            </tr>
            {props.usuarios ? (
              props.usuarios.map((usuario) => (
                <tr className="tabla-body">
                  <td>{usuario.nombre}</td>
                  <td>{usuario.email}</td>
                  <td style={{ display: "flex", gap: "10px" }}>
                    <Link
                      className="boton-azul"
                      to={`/editar-usuario/${usuario.id}`}
                    >
                      Editar
                    </Link>
                    <button
                      onClick={() => {
                        eliminar(usuario.id);
                      }}
                      className="boton-rojo"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <h1>cargando..</h1>
            )}
          </table>
        </div>
      </div>

      <div style={{ marginTop: "50px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p className="titulo-1">Lista de Vendedores</p>
          <div style={{ display: "flex", gap: "10px" }}>
            <Link className="boton-morado" to="/nuevo-vendedor">
              Nuevo Vendedor
            </Link>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
          <table>
            <tr className="tabla-header">
              <td>Nombre</td>
              <td>Accion</td>
            </tr>
            {props.vendedores ? (
              props.vendedores.map((vendedor) => (
                <tr className="tabla-body">
                  <td>{vendedor.nombre}</td>
                  <td style={{ display: "flex", gap: "10px" }}>
                    <Link
                      className="boton-azul"
                      to={`/editar-vendedor/${vendedor.id}`}
                    >
                      Editar
                    </Link>
                    <button
                      onClick={() => {
                        eliminarVendedor(vendedor.id);
                      }}
                      className="boton-rojo"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <h1>cargando..</h1>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "usuarios",
    },
    {
      collection: "vendedores",
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    usuarios: ordered.usuarios,
    vendedores: ordered.vendedores,
  }))
)(Usuarios);
