import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

const EditarProducto = (props) => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  let setResult = global.setResult;
  setTitle("Editar Producto");

  const { id } = useParams();

  useFirestoreConnect([
    {
      collection: "productos",
      doc: id,
    },
  ]);

  const producto = useSelector(
    ({ firestore: { data } }) => data.productos && data.productos[id]
  );

  // eslint-disable-next-line
  const [consulta, setConsulta] = useState({ consulta: "" });
  const [state, setState] = useState({
    nombre: "",
    modelo: "",
    codigo: "",
    precio: "",
    precio_iva: "",
  });

  const [result] = useDatabase(consulta);

  useEffect(() => {
    if (producto) {
      setState({
        ...state,
        ...producto,
      });
    }
  }, [producto]);

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const editar = (e) => {
    e.preventDefault();

    setConsulta({
      consulta: "actualizar",
      collection: "productos",
      state,
      id,
      url: "/productos",
    });
  };

  return (
    <div className="row contenedor-1">
      {!producto ? (
        <h1>cargando...</h1>
      ) : (
        <form onSubmit={editar}>
          <div className="col s6">
            <p className="titulo-1">Información del producto</p>
            <div className="input-field">
              <input
                onChange={leerDato}
                defaultValue={state.nombre}
                id="nombre"
                name="nombre"
                type="text"
                className="validate"
                required
              />
              <label className="active" for="nombre">
                Nombre del producto:
              </label>
            </div>
            <div className="input-field">
              <input
                onChange={leerDato}
                defaultValue={state.modelo}
                id="modelo"
                name="modelo"
                type="text"
                className="validate"
              />
              <label className="active" for="modelo">
                Modelo:
              </label>
            </div>
            <div className="input-field">
              <input
                onChange={leerDato}
                defaultValue={state.precio}
                id="precio"
                name="precio"
                type="number"
                className="validate"
                required
              />
              <label className="active" for="precio">
                Precio:
              </label>
            </div>
            <div className="input-field">
              <input
                onChange={leerDato}
                defaultValue={state.precio_iva}
                id="precio_iva"
                name="precio_iva"
                type="number"
                className="validate"
                required
              />
              <label className="active" for="precio_iva">
                Precio con IVA:
              </label>
            </div>
          </div>
          <div
            className="col s6"
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <button type="submit" className="boton-verde">
              Actualizar
            </button>
            <Link className="boton-azul" to="/productos">
              Regresar
            </Link>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditarProducto;
