import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

const Productos = (props) => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Productos");

  const [consulta, setConsulta] = useState({ consulta: "" });
  useDatabase(consulta);

  const eliminarProducto = (id) => {
    setConsulta({ consulta: "eliminar", collection: "productos", id });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 500);
  };

  return (
    <div className="contenedor-1">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p className="titulo-1">Lista de Productos</p>
        <div style={{ display: "flex", gap: "10px" }}>
          <Link className="boton-morado" to="/nuevo-producto">
            Nuevo Producto
          </Link>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "50px" }}>
        <table>
          <tr className="tabla-header">
            <td>Nombre</td>
            <td>Precio</td>
            <td>Precio IVA</td>
            <td>Accion</td>
          </tr>
          {props.productos ? (
            props.productos.map((producto) => (
              <tr className="tabla-body">
                <td>{producto.nombre}</td>
                <td>${producto.precio}</td>
                <td>${producto.precio_iva}</td>
                <td style={{ display: "flex", gap: "10px" }}>
                  <Link
                    className="boton-azul"
                    to={`/editar-producto/${producto.id}`}
                  >
                    Editar
                  </Link>
                  <button
                    onClick={() => {
                      eliminarProducto(producto.id);
                    }}
                    className="boton-rojo"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <h1>cargando..</h1>
          )}
        </table>
      </div>
    </div>
  );
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "productos",
      orderBy: "nombre",
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    productos: ordered.productos,
  }))
)(Productos);
