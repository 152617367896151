import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";

import user from "../Images/user.svg";
import pass from "../Images/password.svg";

import firebaseHelper from "../Helpers/Firebase";

import { globalContext } from "../Context/globalContext";
import Swal from "sweetalert2";

const Index = (props) => {
  const [passwordType, setPasswordType] = useState("password");
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const userGlobal = useContext(globalContext);

  const password = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const login = async (e) => {
    e.preventDefault();
    const { firestore } = props;

    let res = await firebaseHelper.login(firestore, state);

    if (res) {
      userGlobal.setUserGlobal(res);

      navigate("/usuarios");
    } else {
      Swal.fire({
        title: "Error!",
        text: "Usuario o contraseña incorrecta",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <form onSubmit={login}>
        <div className="login center sombra">
          <p style={{ fontSize: "30px", fontWeight: "bold" }}>
            Inisio de sesión
          </p>
          <div className="login-form">
            <img src={user} alt="icon user" />
            <input
              onChange={leerDato}
              placeholder="E-mail"
              id="email"
              name="email"
              type="email"
              className="validate"
            />
          </div>
          <div className="login-form">
            <img onClick={password} src={pass} alt="icon password" />
            <input
              onChange={leerDato}
              placeholder="Password"
              id="password"
              name="password"
              type={passwordType}
              className="validate"
            />
          </div>
          <button className="boton-login" type="submit">
            Entrar
          </button>
        </div>
      </form>
    </div>
  );
};

export default firestoreConnect()(Index);
