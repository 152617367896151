import { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import { useFirestore, useFirebase } from "react-redux-firebase";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../Context/globalContext";

const useDatabase = ({
  consulta,
  collection,
  state,
  id,
  url,
  urlFiles,
  file,
}) => {
  const firestore = useFirestore();
  const firebase = useFirebase();
  const history = useNavigate();
  const [result, setResult] = useState(false);

  const global = useContext(globalContext);
  let loader = global.loader;
  let setLoader = global.setLoader;

  const deletArrayImages = () => {
    if (0 === urlFiles.length) {
      actualizarProducto();
    } else {
      deleteFile(0);

      function deleteFile(index) {
        // Create a reference to the file to delete
        const file = firebase.storage().refFromURL(urlFiles[index].result);

        file
          .delete()
          .then(() => {
            if (index === urlFiles.length - 1) {
              actualizarProducto();
            } else {
              let nuevoIndex = index + 1;
              deleteFile(nuevoIndex);
            }
          })
          .catch((error) => {
            // console.log({error});
          });
      }
    }
  };

  const deleteBucket = () => {
    // Create a reference to the file to delete
    const bucket = firebase.storage().ref(`/productos/${id}/`);

    bucket
      .listAll()
      .then((dir) => {
        deleteFile(dir.items, 0);
      })
      .catch((error) => {
        setResult(false);
        Swal.fire({
          title: "No se logro eliminar",
          text: "La informacon no se logro eliminar corectamente",
          confirmButtonColor: "#2DCD22",
          confirmButtonText: "Continuar",
          icon: "error",
        });
      });

    function deleteFile(files, index) {
      const archivo = files[index];

      archivo
        .delete()
        .then(() => {
          if (index === files.length - 1) {
            eliminarSinMensaje();
          } else {
            let nuevoIndex = index + 1;
            deleteFile(files, nuevoIndex);
          }
        })
        .catch((error) => {
          // console.log({error});
        });
    }
  };

  const agregar = () => {
    if (loader) setLoader(false);

    firestore
      .collection(collection)
      .doc()
      .set(state)
      .then(() => {
        setLoader(true);

        setTimeout(() => {
          Swal.fire({
            title: "Inserción exitosa",
            text: "El campo fue insertado exitosamente",
            confirmButtonColor: "#2DCD22",
            confirmButtonText: "Continuar",
            icon: "success",
          }).then(() => {
            setResult(Math.random() * 9999);
            if (url) {
              history(url);
            }
          });
        }, 100);
      });
  };

  const actualizar = () => {
    Swal.fire({
      title: "¿Esta seguro de actualizar este campo?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#2DCD22",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (loader) setLoader(false);
        firestore
          .update(
            {
              collection: collection,
              doc: id,
            },
            state
          )
          .then(() => {
            setLoader(true);
            Swal.fire({
              title: "Modificacion exitosa",
              text: "El campo fue modificado exitosamente",
              confirmButtonColor: "#2DCD22",
              confirmButtonText: "Continuar",
              icon: "success",
            }).then(() => {
              setResult(true);
              history(url);
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelado",
          text: "El campo sigue intacto :)",
          confirmButtonColor: "#2DCD22",
          confirmButtonText: "Continuar",
          icon: "error",
        }).then(() => {
          setResult(false);
        });
      }
    });
  };

  const actualizarProducto = () => {
    if (loader) setLoader(false);

    firestore
      .update(
        {
          collection: collection,
          doc: id,
        },
        state
      )
      .then(() => {
        setLoader(true);

        setTimeout(() => {
          Swal.fire({
            title: "Modificacion exitosa",
            text: "El campo fue modificado exitosamente",
            confirmButtonColor: "#2DCD22",
            confirmButtonText: "Continuar",
            icon: "success",
          }).then(() => {
            setResult(true);
            history(url);
          });
        }, 100);
      });
  };

  const eliminar = () => {
    Swal.fire({
      title: "¿Esta seguro de eliminar este campo?",
      text: "No se podra recuperar!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#2DCD22",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (loader) setLoader(false);

        firestore
          .delete({
            collection: collection,
            doc: id,
          })
          .then(() => {
            setLoader(true);
            setResult(true);
            Swal.fire({
              title: "Eliminado con exito",
              text: "El campo fue eliminado exitosamente",
              confirmButtonColor: "#2DCD22",
              confirmButtonText: "Continuar",
              icon: "success",
            });
          });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        setResult(false);
        Swal.fire({
          title: "Cancelado",
          text: "El campo sigue intacto :)",
          confirmButtonColor: "#2DCD22",
          confirmButtonText: "Continuar",
          icon: "error",
        });
      }
    });
  };

  const eliminarProducto = () => {
    Swal.fire({
      title: "¿Esta seguro de eliminar este campo?",
      text: "No se podra recuperar!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#2DCD22",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (loader) setLoader(false);

        deleteBucket();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        setResult(false);
        Swal.fire({
          title: "Cancelado",
          text: "El campo sigue intacto :)",
          confirmButtonColor: "#2DCD22",
          confirmButtonText: "Continuar",
          icon: "error",
        });
      }
    });
  };

  const eliminarSinMensaje = () => {
    firestore
      .delete({
        collection: collection,
        doc: id,
      })
      .then(() => {
        setLoader(true);
        setResult(true);
        Swal.fire({
          title: "Eliminado con exito",
          text: "El campo fue eliminado exitosamente",
          confirmButtonColor: "#2DCD22",
          confirmButtonText: "Continuar",
          icon: "success",
        });
      });
  };

  const subirImagen = () => {
    if (loader) setLoader(false);

    const storageRef = firebase
      .storage()
      .ref(`/productos/${id}/${state.nombre}-${file.name}`);
    const task = storageRef.put(file);

    task.on(
      "state_changed",
      (snapshot) => {
        // let percentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 500;
      },
      (error) => {
        setResult(error);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then((url) => {
          setResult(url);
        });
      }
    );
  };

  useEffect(() => {
    if (consulta === "agregar") {
      agregar();
    } else if (consulta === "actualizar") {
      actualizar();
    } else if (consulta === "eliminar") {
      eliminar();
    } else if (consulta === "eliminar_producto") {
      eliminarProducto();
    } else if (consulta === "eliminar_imagen_url") {
      deletArrayImages();
    } else if (consulta === "subir_imagen") {
      subirImagen();
    }
    // eslint-disable-next-line
  }, [consulta]);

  return [result];
};

export default useDatabase;
