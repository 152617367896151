import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

const NuevoProducto = (props) => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Nuevo Producto");

  const [consulta, setConsulta] = useState({ consulta: "" });
  const [state, setState] = useState({
    nombre: "",
    modelo: "",
    precio: "",
    precio_iva: "",
  });
  useDatabase(consulta);

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const agregarProducto = (e) => {
    e.preventDefault();

    const { firestore } = props;

    let data = {
      ...state,
    };

    setConsulta({
      consulta: "agregar",
      collection: "productos",
      state: data,
      url: "/productos",
    });
  };

  return (
    <div className="row contenedor-1">
      <form
        onSubmit={(e) => {
          agregarProducto(e, 0);
        }}
      >
        <div className="col s6">
          <p className="titulo-1">Información del producto</p>
          <div className="input-field">
            <input
              onChange={leerDato}
              id="nombre"
              name="nombre"
              type="text"
              className="validate"
              required
            />
            <label for="nombre">Nombre del producto:</label>
          </div>
          <div className="input-field">
            <input
              onChange={leerDato}
              id="modelo"
              name="modelo"
              type="text"
              className="validate"
            />
            <label for="modelo">Modelo:</label>
          </div>
          <div className="input-field">
            <input
              onChange={leerDato}
              id="precio"
              name="precio"
              type="number"
              className="validate"
              step="any"
              required
            />
            <label for="precio">Precio:</label>
          </div>
          <div className="input-field">
            <input
              onChange={leerDato}
              id="precio_iva"
              name="precio_iva"
              type="number"
              className="validate"
              step="any"
              required
            />
            <label for="precio_iva">Precio con IVA:</label>
          </div>
        </div>
        <div
          className="col s6"
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <button type="submit" className="boton-verde">
            Agregar
          </button>
          <Link className="boton-azul" to="/productos">
            Regresar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "categorias",
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    categorias: ordered.categorias,
  }))
)(NuevoProducto);
