import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { globalContext } from "../../Context/globalContext";

const Ventas = (props) => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Ventas");

  const MESES = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const [total, setTotal] = useState(0);
  const [totalIVA, setTotalIVA] = useState(0);
  const [excel, setExcel] = useState([]);

  useEffect(() => {
    let copiaTotal = 0;
    let copiaTotalIVA = 0;
    if (props.ventas) {
      // console.log(props.ventas);
      transformData(props.ventas);
      props.ventas.map((venta) => {
        copiaTotal =
          copiaTotal +
          parseFloat(venta.total) * ((100 - venta.descuento) / 100);
        copiaTotalIVA =
          copiaTotalIVA +
          parseFloat(venta.totalIVA) * ((100 - venta.descuento) / 100);
      });

      setTotal(copiaTotal);
      setTotalIVA(copiaTotalIVA);
    }
  }, [props]);

  const transformData = (data) => {
    const transformedData = [];

    // Añadir los encabezados de la tabla
    transformedData.push([
      "ID",
      "Total",
      "Correo",
      "Descuento",
      "Pago",
      "Nombre",
      "Apellido",
      "Estado",
      "Ciudad",
      "Vendedor",
      "Telefono",
      "TotalIVA",
      // Añadir encabezados para los productos
      "Productos",
      //... puedes continuar para tantos productos como esperes tener en una sola transacción
    ]);

    data.forEach((item) => {
      const row = [
        item.id,
        item.total,
        item.correo,
        item.descuento,
        item.pago,
        item.nombre,
        item.apellido,
        item.estado,
        item.ciudad,
        item.vendedor,
        item.telefono,
        item.totalIVA,
      ];

      item.productos.forEach((producto, index) => {
        // Añade el nombre del producto y la cantidad a la fila
        // Index * 2 + 12 es porque estamos empezando en la 12ª columna y cada producto ocupa 2 columnas
        row[
          index * 2 + 12
        ] = `${producto.cantidad} ${producto.nombre}-${producto.modelo} => Precio:${producto.precio}/Precio IVA:${producto.precio_iva}`;
      });

      transformedData.push(row);
    });

    setExcel(transformedData);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.aoa_to_sheet(excel);

    const wscols = excel[0].map((_, i) => {
      const colData = excel.map((row) => row[i]);
      const longestCell = colData.reduce((longest, cell) => {
        if (String(cell).length > longest.length) {
          return String(cell);
        }
        return longest;
      }, "");

      return { wch: longestCell.length + 1 }; // +1 for a bit of padding
    });

    ws["!cols"] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "data.xlsx");
  };

  return (
    <div className="contenedor-1">
      <div style={{ maxHeight: "65vh", overflow: "auto" }}>
        <table>
          <tr className="tabla-header">
            <td>Fecha</td>
            <td>Cliente</td>
            <td>Correo</td>
            <td>Numero</td>
            <td>Precio</td>
            <td>Precio IVA</td>
            <td>Descuento</td>
            <td>Accion</td>
          </tr>
          {props.ventas ? (
            props.ventas.map((venta) => (
              <tr className="tabla-body">
                <td>
                  {new Date(venta.fecha.seconds * 1000).getDate()}/
                  {MESES[new Date(venta.fecha.seconds * 1000).getMonth()]}/
                  {new Date(venta.fecha.seconds * 1000).getFullYear()}(
                  {new Date(venta.fecha.seconds * 1000).getHours()}:
                  {new Date(venta.fecha.seconds * 1000).getMinutes()})
                </td>
                <td>
                  {venta.nombre} {venta.apellido}
                </td>
                <td>{venta.correo}</td>
                <td>{venta.telefono}</td>
                <td>${venta.total}</td>
                <td>${venta.totalIVA}</td>
                <td>%{venta.descuento}</td>
                <td style={{ display: "flex", gap: "10px" }}>
                  <Link className="boton-azul" to={`/venta/${venta.id}`}>
                    Ver
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <h1>cargando..</h1>
          )}
        </table>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <h4 style={{ margin: "0" }}>
          Ventas totales: ${parseFloat(total).toFixed(2)}
        </h4>
        <h4 style={{ margin: "0" }}>
          Ventas totales + IVA: ${parseFloat(totalIVA).toFixed(2)}
        </h4>
      </div>
      <button
        className="boton-azul"
        style={{ marginTop: "30px" }}
        onClick={exportToExcel}
      >
        Exportar a Excel
      </button>
    </div>
  );
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "ventas",
      orderBy: "fecha",
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    ventas: ordered.ventas,
  }))
)(Ventas);
