import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CryptoJS from "react-native-crypto-js";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

const EditarVendedor = () => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Editar Vendedor");

  const { id } = useParams();

  useFirestoreConnect([
    {
      collection: "vendedores",
      doc: id,
    },
  ]);

  const vendedor = useSelector(
    ({ firestore: { data } }) => data.vendedores && data.vendedores[id]
  );

  const [consulta, setConsulta] = useState({ consulta: "" });
  const [state, setState] = useState(false);

  useDatabase(consulta);

  const ediarVendedor = (e) => {
    e.preventDefault();

    setConsulta({
      consulta: "actualizar",
      collection: "vendedores",
      state,
      id,
      url: "/usuarios",
    });

    setTimeout(() => {
      setConsulta({ consulta: "" });
    }, 500);
  };

  const leerDato = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (vendedor) {
      setState({
        ...vendedor,
      });
    }
  }, [vendedor]);

  return (
    <div className="row contenedor-1">
      <form onSubmit={ediarVendedor}>
        <div className="col s6">
          <p className="titulo-1">Información del vendedor</p>
          <div className="input-field">
            <input
              onChange={leerDato}
              defaultValue={state.nombre}
              id="nombre"
              name="nombre"
              type="text"
              className="validate"
              required
            />
            <label className="active" for="nombre">
              Nombre de usuario:
            </label>
          </div>
        </div>
        <div
          className="col s6"
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <button type="submit" className="boton-verde">
            Actualizar
          </button>
          <Link className="boton-azul" to="/usuarios">
            Regresar
          </Link>
        </div>
      </form>
    </div>
  );
};

export default EditarVendedor;
