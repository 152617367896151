import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { globalContext } from "../../Context/globalContext";
import useDatabase from "../../Hooks/useDatabase";

import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import Loader from "../../Components/Loader/Loader";

const Venta = () => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  let setResult = global.setResult;
  setTitle("Información de venta");

  const MESES = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const { id } = useParams();

  useFirestoreConnect([
    {
      collection: "ventas",
      doc: id,
    },
  ]);

  const venta = useSelector(
    ({ firestore: { data } }) => data.ventas && data.ventas[id]
  );

  if (!venta) {
    return <Loader />;
  } else {
    return (
      <div className="contenedor-1 row">
        <div className="col s6">
          <p className="titulo-1">
            {new Date(venta.fecha.seconds * 1000).getDate()} de{" "}
            {MESES[new Date(venta.fecha.seconds * 1000).getMonth()]} del{" "}
            {new Date(venta.fecha.seconds * 1000).getFullYear()} (
            {new Date(venta.fecha.seconds * 1000).getHours()}
            {":"}
            {new Date(venta.fecha.seconds * 1000).getMinutes()})
          </p>

          <div>
            <p>
              <b>Datos del Cliente</b>
            </p>
            <p>
              Nombre:{" "}
              <b>
                {venta.nombre} {venta.apellido}
              </b>
            </p>
            <p>
              Correo: <b>{venta.correo}</b>
            </p>
            <p>
              Telefono: <b>{venta.telefono}</b>
            </p>
          </div>

          <div style={{ marginTop: "40px" }}>
            <p>
              <b>Datos de la venta</b>
            </p>
            <p>
              Vendedor: <b>{venta.vendedor}</b>
            </p>
            <p>
              Descuento: <b>%{venta.descuento}</b>
            </p>
            <p>
              Tipo de pago: <b>{venta.pago}</b>
            </p>
          </div>

          <div style={{ marginTop: "30px" }}>
            <Link className="boton-azul" to="/ventas">
              Regresar
            </Link>
          </div>
        </div>
        <div className="col s6">
          <table>
            <tr className="tabla-header">
              <td>Productos</td>
              <td>Cantidad</td>
              <td>Precio</td>
              <td>Precio IVA</td>
            </tr>
            {venta.productos.map((producto) => (
              <tr className="tabla-body">
                <td>{producto.nombre}</td>
                <td>{producto.cantidad}</td>
                <td>${producto.precio}</td>
                <td>${producto.precio_iva}</td>
              </tr>
            ))}
          </table>
          <div style={{ marginTop: "20px" }}>
            <p style={{ margin: "0" }}>
              Total: <b>${venta.total}</b>
            </p>
            <p style={{ margin: "0" }}>
              Total + IVA: <b>${venta.totalIVA}</b>
            </p>
          </div>
          <p style={{ margin: "0" }}>-----------------------</p>
          {venta.descuento !== 0 && (
            <div>
              <p style={{ margin: "0" }}>
                Total con descuento:{" "}
                <b>
                  $
                  {parseFloat(
                    parseFloat(venta.total) * ((100 - venta.descuento) / 100)
                  ).toFixed(2)}
                </b>
              </p>
              <p style={{ margin: "0" }}>
                Total + IVA con descuento:{" "}
                <b>
                  $
                  {parseFloat(
                    parseFloat(venta.totalIVA) * ((100 - venta.descuento) / 100)
                  ).toFixed(2)}
                </b>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default Venta;
