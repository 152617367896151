import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { globalContext } from "../../Context/globalContext";

const Clientes = (props) => {
  const global = useContext(globalContext);
  let setTitle = global.setTitle;
  setTitle("Datos Clientes");

  const ticketRef = useRef(null);
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    let copiaClientes = [];
    let copiaClientesDatos = [];
    if (props.ventas) {
      props.ventas.map((venta) => {
        if (!copiaClientesDatos.includes(venta.nombre + " " + venta.apellido)) {
          copiaClientes.push(venta);
          copiaClientesDatos.push(venta.nombre + " " + venta.apellido);
        }
      });

      setClientes(copiaClientes);
    }
  }, [props]);

  const imrpimir = async () => {
    if (ticketRef.current) {
      const content = ticketRef.current.innerHTML;
      const printWindow = window.open("", "", "height=1000,width=1900");
      printWindow.document.write("<html><head><title>Datos Clientes</title>");
      printWindow.document.write("</head><body>");
      printWindow.document.write(content);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      setTimeout(() => {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }, 100);
    }
  };

  return (
    <div className="contenedor-1">
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "71vh",
          overflow: "auto",
        }}
      >
        {clientes.map((cliente) => (
          <div className="contenedor-cliente">
            <p>
              Nombre: {cliente.nombre} {cliente.apellido}
            </p>
            <p>Telefono: {cliente.telefono}</p>
            <p>Correo: {cliente.correo}</p>
            <p>
              Dirección: {cliente.ciudad}, {cliente.estado}
            </p>
          </div>
        ))}
      </div>
      <button
        style={{ marginTop: "20px" }}
        onClick={imrpimir}
        className="boton-morado"
      >
        imprimir
      </button>

      {/* informaciona imprimir */}
      <div style={{ display: "none" }} ref={ticketRef}>
        <div
          style={{
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {clientes.map((cliente) => (
            <div
              style={{
                border: "solid 1px #6d6d6d",
                borderRadius: "15px",
                width: "calc(40% - 25px)",
                padding: "0px 10px",
              }}
            >
              <p>
                Nombre: {cliente.nombre} {cliente.apellido}
              </p>
              <p>Telefono: {cliente.telefono}</p>
              <p>Correo: {cliente.correo}</p>
              <p>
                Dirección: {cliente.ciudad}, {cliente.estado}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default compose(
  firestoreConnect((props) => [
    {
      collection: "ventas",
      orderBy: "nombre",
    },
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    ventas: ordered.ventas,
  }))
)(Clientes);
