import React from "react";

const NuevaVenta = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      zoomAndPan="magnify"
      viewBox="0 0 37.5 37.499999"
      width={22}
      height={22}
      preserveAspectRatio="xMidYMid meet"
      version={1.0}
    >
      <defs>
        <clipPath id="81bbbd7f4a">
          <path
            d="M 1.777344 1.777344 L 35.527344 1.777344 L 35.527344 35.527344 L 1.777344 35.527344 Z M 1.777344 1.777344 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#81bbbd7f4a)">
        <path
          fill="#bbbbbb"
          d="M 18.652344 1.777344 C 9.34375 1.777344 1.777344 9.34375 1.777344 18.652344 C 1.777344 27.960938 9.34375 35.527344 18.652344 35.527344 C 27.960938 35.527344 35.527344 27.960938 35.527344 18.652344 C 35.527344 9.34375 27.960938 1.777344 18.652344 1.777344 Z M 28.136719 20.285156 L 20.285156 20.285156 L 20.285156 28.136719 C 20.285156 29.027344 19.558594 29.769531 18.652344 29.769531 C 17.75 29.769531 17.019531 29.027344 17.019531 28.136719 L 17.019531 20.285156 L 9.171875 20.285156 C 8.277344 20.285156 7.539062 19.558594 7.539062 18.652344 C 7.539062 17.75 8.277344 17.019531 9.171875 17.019531 L 17.019531 17.019531 L 17.019531 9.171875 C 17.019531 8.277344 17.75 7.539062 18.652344 7.539062 C 19.558594 7.539062 20.285156 8.277344 20.285156 9.171875 L 20.285156 17.019531 L 28.136719 17.019531 C 29.027344 17.019531 29.769531 17.75 29.769531 18.652344 C 29.769531 19.558594 29.027344 20.285156 28.136719 20.285156 Z M 28.136719 20.285156 "
          fillOpacity={1}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default NuevaVenta;
